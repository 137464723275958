<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>

      <div class="scrollbar-container">


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/wagetype
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in wageTypes" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>

        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/bmdwagetype
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in bmdWageTypes" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/socialinsuranceregistrationmode
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in socialinsuranceregistrationmodes" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/document
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in documents" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/citizentype
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in citizentypes" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>

        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/civilstatus
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in civilstatuss" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/gender
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in genders" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/religioussociety
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in religioussocietys" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>




        <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
          /api/enum/country
        </div>
        <table class="table">
          <tr>
            <th style="width:10%">id</th>
            <th style="width:25%">key</th>
            <th style="width:25%">valueGER</th>
            <th style="width:25%">valueENG</th>
            <th style="width:15%">deleted_at</th>
          </tr>
          <tr v-for="row in countries" v-bind:key="'e'+row.id">
            <td>{{row.id}}</td>
            <td>{{row.key}}</td>
            <td>{{row.valueGER}}</td>
            <td>{{row.valueENG}}</td>
            <td>{{row.deleted_at}}</td>
          </tr>
        </table>


      </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'superadminEnums',
  data () {
    return {
      citizentypes: [],
      civilstatuss: [],
      genders: [],
      religioussocietys: [],
      countries: [],
      documents: [],
      socialinsuranceregistrationmodes: [],
      wageTypes: [],
      bmdWageTypes: [],
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
        ...mapGetters(['companyId', 'isSuperUser']),
        dateDisplay: function() {
          return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
          headerButtons() {
              let view = this;
              let state= { buttons: [] };
              /*
              state.buttons.push({ type: "block", icon: "fa-eye", click: () => { }, tooltip: 'VIEW' });
              state.buttons.push({ type: "block", icon: "fa-cloud-download-alt", click: () => { view.loadExternalUsersFromKundencenter(); }, tooltip: 'SYNC' });
              */
              return state;
          },
        timeZoneList() {
            let result = [];
            /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
            return result;
        },
  },
  watch: {
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.loadEnums();
    },

    loadEnums: function(force = false){
      let view = this;


      url = '/api/enum/citizentype';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.citizentypes = response.data;
      }).catch(function (error) {
        console.log(error);
      })

      url = '/api/enum/civilstatus';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.civilstatuss = response.data;
      }).catch(function (error) {
        console.log(error);
      })

      let url = '/api/enum/gender';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.genders = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/religioussociety';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.religioussocietys = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/country';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.countries = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/document';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.documents = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/socialinsuranceregistrationmode';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.socialinsuranceregistrationmodes = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/wagetype';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.wageTypes = response.data;
      }).catch(function (error) {
        console.log(error);
      })


      url = '/api/enum/bmdwagetype';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.bmdWageTypes = response.data;
      }).catch(function (error) {
        console.log(error);
      })

    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px;
  text-align:left;
  float:left;
  margin-left:25px;
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
